import { Link } from '@loveholidays/design-system';
import React, { createElement, ReactNode, ElementType, Fragment } from 'react';
import RehypeReact from 'rehype-react';

import { HastNode } from '@Core/types';
import { List } from '@UX/List/List';
import { Paragraph } from '@UX/Text/Paragraph/Paragraph';

interface HastComponentConfig {
  [htmlElement: string]: {
    Component: ElementType;
    Props?: any;
  };
}

export const hastToReact = (
  hastNode?: HastNode,
  components: HastComponentConfig = {},
): ReactNode =>
  hastNode
    ? new RehypeReact({
        createElement: createElement,
        Fragment: Fragment,
        components: {
          p: Paragraph,
          ul: List,
          a: Link,
          ...Object.keys(components).reduce((prev, key) => {
            const { Component, Props = {} } = components[key];

            return {
              ...prev,
              [key]: (props: any): ReactNode => (
                <Component
                  {...props}
                  {...Props}
                />
              ),
            };
          }, {}),
        },
      }).Compiler(hastNode)
    : '';
