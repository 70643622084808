import React from 'react';

import { Text } from '../Text';
import { ClassNameProps } from '@ComponentProps';
import { TextVariants } from '@UX/themes/types';

interface ParagraphProps extends ClassNameProps {
  variant?: keyof TextVariants;
}

export const Paragraph: React.FC<ParagraphProps> = ({
  className,
  children,
  variant = 'regular',
}) => (
  <Text
    className={className}
    variant={variant}
    sx={{
      marginTop: '3xs',
      marginBottom: 'xs',
    }}
  >
    {children}
  </Text>
);
